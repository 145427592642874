import React from "react"
import { withPreview } from "gatsby-source-prismic"
import { graphql } from "gatsby"

import type { PromotionPageType } from "../types/PromotionPageType"
import type { UserType } from "@lesmills/gatsby-theme-common"

import {
  renderSEO,
  Layout,
  PublicRoute,
  DATA_LAYER_SCRIPT,
} from "@lesmills/gatsby-theme-common"

import Promotion from "../components/Promotion"

type Props = {|
  pageContext: {
    location: Object,
    lang: string,
  },
  data: PromotionPageType,
|}

const PromotionPage = (props: Props) => {
  const { pageContext, location, data } = props || {}
  const scriptsPromotionPage = [DATA_LAYER_SCRIPT]

  const lang = pageContext.unPublishedLang || pageContext.lang
  if (!data) return null

  const { prismicLayout = {}, prismicPromotionPages = {} } = data

  const promotionData = prismicPromotionPages.data || {}
  const layout = prismicLayout.data || {}

  const renderPromotionPage = ({ user, checkingSession }: UserType) => (
    <Layout
      data={layout}
      headerless
      lang={lang}
      user={user}
      isLoading={checkingSession}
    >
      {renderSEO(promotionData, location.pathname, lang, scriptsPromotionPage)}
      <Promotion
        user={user}
        promotionData={promotionData}
        layout={layout}
        lang={lang}
      />
    </Layout>
  )

  return <PublicRoute component={renderPromotionPage} {...props} lang={lang} />
}

export const query = graphql`
  query promotionQuery($lang: String) {
    prismicLayout(lang: { eq: $lang }) {
      data {
        ...LayoutUnits
        best_value {
          raw
          text
        }
        body1 {
          ...LayoutBody1Navigation
        }
        sign_in_button {
          raw
          text
        }
        les_mills_plus_logo {
          alt
          url
        }
      }
    }
    prismicPromotionPages(lang: { eq: $lang }) {
      ...PromotionPages
    }
  }
`

export default withPreview(PromotionPage)
